import React, { useEffect } from 'react';
import '../App.scss';
import "/node_modules/flag-icons/css/flag-icons.min.css"
import "/node_modules/masonry-layout/masonry"

import Heading from '../components/Heading';
import Footer from '../components/Footer';

import Technology from '../components/Technology';
import Testimonials from '../components/Testimonials';
import Mentorship from '../components/Mentorship';
import Products from '../components/Products';
import Media from '../components/Media';

function Home() {
  useEffect(() => {
      document.title = 'Radd Studio Inc. // web .:. software .:. ideas'
  })
  return (
    <main className="Home">
      <Heading />
      <Technology />
      <Mentorship />
      <Media />
      <Products />
      <Testimonials />
      <Footer />
    </main>
  );
}

export default Home;



