import React, { useRef } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCoffee, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Page from './Page'; // Assuming you have a Page component

// Load FontAwesome Icons
library.add(fab, faCoffee, faEnvelope);

const Heading = () => {
  const svgRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  
  return (
    <Carousel
      variant="dark"
      interval={3000}  
      controls={true}  
      indicators={true}
      style={{ maxHeight: '100vh', width: '100vw' }}  
    >
      <Carousel.Item>
        <Page>
          <Row>
            <Col className="">
              <div
                className="d-block w-100"
                style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                <img
                  onClick={handleEmailClick}
                  ref={svgRefs[3]}
                  src="/assets/svgs/email.svg"
                  alt="email"
                  style={{ width: '100%', height: '100%', imgFit: 'cover', margin: 0, padding: 0}}
                />
              </div>
            </Col>
          </Row>
        </Page>
      </Carousel.Item>

      <Carousel.Item>
        <Page>
          <Row>
            <Col className="">
              <div
                className="d-block w-100"
                style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                <img
                  onClick={handleBizClick}
                  ref={svgRefs[0]}
                  src="/assets/svgs/biz.svg"
                  alt="biz"
                  style={{ width: '100%', height: '100%', imgFit: 'cover', margin: 0, padding: 0}}
                />
              </div>
            </Col>
          </Row>
        </Page>
      </Carousel.Item>

      <Carousel.Item>
        <Page>
          <Row>
            <Col className="">
              <div
                className="d-block w-100"
                style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                <img
                  onClick={handleShopClick}
                  ref={svgRefs[2]}
                  src="/assets/svgs/shop.svg"
                  alt="shop"
                  style={{ width: '100%', height: '100%', imgFit: 'cover', margin: 0, padding: 0}}
                />
              </div>
            </Col>
          </Row>
        </Page>
      </Carousel.Item>

      <Carousel.Item>
        <Page>
          <Row>
            <Col className="">
              <div
                className="d-block w-100"
                style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                <img
                  onClick={handleProClick}
                  ref={svgRefs[1]}
                  src="/assets/svgs/pro.svg"
                  alt="pro"
                  style={{ width: '100%', height: '100%', imgFit: 'cover', margin: 0, padding: 0}}
                />
              </div>
            </Col>
          </Row>
        </Page>
      </Carousel.Item>
    </Carousel>
  );
};

function handleEmailClick() {
  debugger;
  window.open('https://clickstudio.email', '_blank', 'noopener,noreferrer'); // Opens the URL in a new tab
};
function handleBizClick() {
  debugger;
  window.open('https://clickstudio.biz', '_blank', 'noopener,noreferrer'); // Opens the URL in a new tab
};
function handleShopClick() {
  debugger;
  window.open('https://clickstudio.shop', '_blank', 'noopener,noreferrer'); // Opens the URL in a new tab
};
function handleProClick() {
  debugger;
  window.open('https://clickstudio.pro', '_blank', 'noopener,noreferrer'); // Opens the URL in a new tab
};

export default Heading;
